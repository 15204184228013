.pricings .card {
  background: #51cada;
  border: solid 3px white;
  border-radius: 60px;
  margin-left: 20px;
  width: 96%;
  box-shadow: 0 0.5rem 1rem 0 rgb(255 254 254 / 56%);
}

.pricings hr {
  margin: 1.5rem 0;
}

.pricings .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricings .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricings .card-price .period {
  font-size: 0.8rem;
}

.pricings ul li {
  margin-bottom: 1rem;
}

.pricings .text-muted {
  opacity: 0.7;
}

.pricings .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  /* opacity: 0.7; */
}
