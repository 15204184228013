html body {
  font-family: sans-serif;
}

a {
  font-family: sans-serif;
}
a.b {
  font-family: sans-serif;
}

h1 h2 h3 h4 h5 h5 p b ul li span  {
  font-family: sans-serif;
}

.bgcolor{
  background: linear-gradient(to right top,#7d7f94,#10163a);
}









@media (min-width: 1200px) {
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  

}
@media (min-width: 1200px) and (max-width: 1400px) {
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  

}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  
}

 
/* small mobile :320px. */
@media (max-width: 767px) {
	html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  
}
 
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  

}
 



@keyframes vibrate {
  0% {
      transform: translateX(0);
  }
  25% {
      transform: translateX(-5px);
  }
  50% {
      transform: translateX(5px);
  }
  75% {
      transform: translateX(-5px);
  }
  100% {
      transform: translateX(5px);
  }
}

/* Apply the vibrate animation to the image and make it loop */
.imgshake {
  animation: vibrate 9s linear infinite;
}