.ukyy::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}
.ukyy::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }

  .dfdestop{
    justify-content: center;
  }

  .center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}